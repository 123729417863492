import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image" 

const About = ({content, logos}) => (
  <div className='about--section p30'>
    <div className='ma large text-initial flex max-900 text-center fade--in' data-sal>
      <div className='op-70'>
      {content}
      </div>
    </div> 
    <div className='logos mt40 text-center pt30 grid fade--in delay-100 max-900 ma' data-sal>
      {logos.map((logo, index) => { 
        return (
          <div className='logo ma flex' key={index}>
            <GatsbyImage className='image ma' image={logo?.gatsbyImageData} alt='Logo' />
          </div>
        )
      })}
    </div>
  </div>
)

export default About
