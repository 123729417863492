import * as React from "react"

const Spacer = ({className=''}) => (
<svg className='logo--main' viewBox="0 0 1134 104" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path className='path' d="M99.68 100.52H63.84V96.6H68.32C72.4267 96.6 74.48 95.2933 74.48 92.68C74.48 92.3067 74.0133 90.72 73.08 87.92L63.56 63H28.28L19.04 86.8C19.04 86.9867 18.7133 88.1533 18.06 90.3C17.4067 92.3533 17.08 93.8 17.08 94.64C17.08 95.3867 17.5933 95.9 18.62 96.18C19.6467 96.46 21.84 96.6 25.2 96.6H27.44V100.52H0V96.6H1.96C5.04 96.6 7.28 96.0867 8.68 95.06C10.08 93.94 11.2933 92.12 12.32 89.6L43.12 9.24001C43.5867 9.14667 44.4267 8.49334 45.64 7.28001C46.8533 5.97334 47.9733 4.66667 49 3.36001C50.0267 1.96 50.54 1.16667 50.54 0.980003C50.54 0.793334 50.4933 0.653333 50.4 0.560001L52.36 0L86.8 90.16C87.8267 92.3067 88.8533 93.94 89.88 95.06C91 96.0867 92.5867 96.6 94.64 96.6H99.68V100.52ZM61.04 57.12L45.92 17.08L30.52 57.12H61.04Z" fill="white"/>
  <path className='path' d="M188.222 102.76L126.342 19.88V90.16C126.342 94.2667 129.236 96.32 135.022 96.32H140.342V100.52H106.742V96.32H111.222C117.009 96.32 119.902 94.2667 119.902 90.16V12.88C119.902 8.77334 117.009 6.72001 111.222 6.72001H106.742V2.52H128.862L186.542 80.08V12.88C186.542 8.77334 183.742 6.72001 178.142 6.72001H173.382V2.52H206.982V6.72001H201.662C195.876 6.72001 192.982 8.77334 192.982 12.88V99.96C191.956 99.96 190.836 100.38 189.622 101.22C188.502 102.153 188.036 102.667 188.222 102.76Z" fill="white"/>
  <path className='path' d="M273.602 100.52H237.202V96.6H242.802C245.602 96.6 247.375 96.0867 248.122 95.06C248.868 93.94 249.242 92.4 249.242 90.44V14.84C249.242 13.44 249.148 12.3667 248.962 11.62C248.775 10.78 248.168 10.0333 247.142 9.38C246.208 8.72667 244.762 8.4 242.802 8.4H234.402C229.455 8.4 225.628 9.94 222.922 13.02C220.308 16.0067 218.908 19.2267 218.722 22.68L214.802 21.84L216.202 2.52H294.882L296.282 21.84L292.362 22.68C292.175 19.32 290.728 16.1 288.022 13.02C285.315 9.94 281.535 8.4 276.682 8.4H268.282C266.228 8.4 264.688 8.77334 263.662 9.52C262.635 10.1733 262.028 10.8733 261.842 11.62C261.655 12.3667 261.562 13.44 261.562 14.84V90.44C261.562 91.7467 261.655 92.7733 261.842 93.52C262.028 94.2667 262.635 94.9667 263.662 95.62C264.688 96.2733 266.228 96.6 268.282 96.6H273.602V100.52Z" fill="white"/>
  <path className='path' d="M406.091 100.52H370.531V96.6H375.011C377.064 96.6 378.604 96.2733 379.631 95.62C380.657 94.8733 381.264 94.1267 381.451 93.38C381.637 92.6333 381.731 91.56 381.731 90.16V52.64H327.411V90.16C327.411 92.2133 327.831 93.8 328.671 94.92C329.511 96.04 331.331 96.6 334.131 96.6H339.451V100.52H303.891V96.6H308.651C311.451 96.6 313.271 96.04 314.111 94.92C314.951 93.8 315.371 92.2133 315.371 90.16V12.88C315.371 10.8267 314.951 9.24001 314.111 8.12C313.271 7 311.451 6.44 308.651 6.44H303.891V2.52H339.451V6.44H334.131C331.331 6.44 329.511 7 328.671 8.12C327.831 9.24001 327.411 10.8267 327.411 12.88V46.48H381.731V12.88C381.731 11.48 381.637 10.4067 381.451 9.66C381.264 8.91334 380.657 8.21334 379.631 7.56C378.604 6.81333 377.064 6.44 375.011 6.44H370.531V2.52H406.091V6.44H400.771C398.717 6.44 397.177 6.81333 396.151 7.56C395.124 8.21334 394.517 8.91334 394.331 9.66C394.144 10.4067 394.051 11.48 394.051 12.88V90.16C394.051 91.56 394.144 92.6333 394.331 93.38C394.517 94.1267 395.124 94.8733 396.151 95.62C397.177 96.2733 398.717 96.6 400.771 96.6H406.091V100.52Z" fill="white"/>
  <path className='path' d="M459.511 103.32C447.284 103.32 437.064 98.6067 428.851 89.18C420.731 79.7533 416.671 68.88 416.671 56.56C416.671 48.9067 418.351 41.5333 421.711 34.44C425.071 27.3467 429.364 21.3267 434.591 16.38C439.911 11.4333 445.744 7.46667 452.091 4.48C458.531 1.49333 464.737 0 470.711 0C482.844 0 493.017 4.71334 501.231 14.14C509.444 23.5667 513.551 34.44 513.551 46.76C513.551 55.9067 511.077 64.8667 506.131 73.64C501.184 82.32 494.464 89.46 485.971 95.06C477.571 100.567 468.751 103.32 459.511 103.32ZM468.191 92.68C478.364 92.68 486.904 88.9467 493.811 81.48C500.811 74.0133 504.311 64.7733 504.311 53.76C504.311 42.7467 500.251 32.8533 492.131 24.08C484.104 15.3067 474.351 10.92 462.871 10.92C453.071 10.92 444.437 14.4667 436.971 21.56C429.597 28.56 425.911 36.96 425.911 46.76C425.911 59.4533 429.784 70.28 437.531 79.24C445.371 88.2 455.591 92.68 468.191 92.68Z" fill="white"/>
  <path className='path' d="M605.625 102.76L543.745 19.88V90.16C543.745 94.2667 546.638 96.32 552.425 96.32H557.745V100.52H524.145V96.32H528.625C534.411 96.32 537.305 94.2667 537.305 90.16V12.88C537.305 8.77334 534.411 6.72001 528.625 6.72001H524.145V2.52H546.265L603.945 80.08V12.88C603.945 8.77334 601.145 6.72001 595.545 6.72001H590.785V2.52H624.385V6.72001H619.065C613.278 6.72001 610.385 8.77334 610.385 12.88V99.96C609.358 99.96 608.238 100.38 607.025 101.22C605.905 102.153 605.438 102.667 605.625 102.76Z" fill="white"/>
  <path className='path' d="M695.764 100.52H660.204V96.6H664.684C667.484 96.6 669.351 96.04 670.284 94.92C671.217 93.8 671.684 92.3067 671.684 90.44V59.92L650.964 18.48C647.137 10.4533 642.191 6.44 636.124 6.44H633.044V2.52H664.684V6.44H663.004C661.511 6.44 660.764 6.90667 660.764 7.84C660.764 8.77334 661.324 10.2667 662.444 12.32L680.084 49.84L698.004 12.32C699.124 10.08 699.684 8.68 699.684 8.12C699.684 7 698.284 6.44 695.484 6.44H691.844V2.52H720.124V6.44H717.884C710.884 6.44 705.844 9.70667 702.764 16.24L683.724 54.32V90.44C683.724 92.3067 684.191 93.8 685.124 94.92C686.057 96.04 687.924 96.6 690.724 96.6H695.764V100.52Z" fill="white"/>
  <path className='path' d="M853.452 100.52H821.532V96.32H824.052C825.545 96.32 826.292 95.9467 826.292 95.2C826.292 94.8267 825.545 93.52 824.052 91.28L795.492 47.04L785.692 56.84V90.16C785.692 91.56 785.785 92.6333 785.972 93.38C786.159 94.1267 786.765 94.8267 787.792 95.48C788.819 96.04 790.359 96.32 792.412 96.32H797.732V100.52H762.172V96.32H766.652C768.705 96.32 770.245 96.04 771.272 95.48C772.299 94.8267 772.905 94.1267 773.092 93.38C773.279 92.6333 773.372 91.56 773.372 90.16V12.88C773.372 11.48 773.279 10.4067 773.092 9.66C772.905 8.91334 772.299 8.26 771.272 7.7C770.245 7.04667 768.705 6.72001 766.652 6.72001H762.172V2.52H797.732V6.72001H792.412C790.359 6.72001 788.819 7.04667 787.792 7.7C786.765 8.26 786.159 8.91334 785.972 9.66C785.785 10.4067 785.692 11.48 785.692 12.88V49.28L821.812 14C823.679 12.32 824.612 10.8267 824.612 9.52C824.612 7.65334 823.212 6.72001 820.412 6.72001H817.892V2.52H845.612V6.72001H843.372C841.692 6.72001 839.265 7.60667 836.092 9.38C832.919 11.1533 830.212 13.16 827.972 15.4L804.172 38.92L835.812 86.52C836.932 88.0133 837.959 89.5067 838.892 91C841.692 94.5467 845.425 96.32 850.092 96.32H853.452V100.52Z" fill="white"/>
  <path className='path' d="M873.047 87.36C875.193 87.36 876.827 88.1067 877.947 89.6C879.16 91.0933 879.767 92.9133 879.767 95.06C879.767 97.2067 878.88 99.0267 877.107 100.52C875.333 102.013 873.373 102.76 871.227 102.76C869.173 102.76 867.54 102.013 866.327 100.52C865.207 99.0267 864.647 97.2533 864.647 95.2C864.647 93.1467 865.533 91.3267 867.307 89.74C869.08 88.1533 870.993 87.36 873.047 87.36Z" fill="white"/>
  <path className='path' d="M951.258 11.76V87.92C956.672 91.0933 964.045 92.68 973.378 92.68C984.485 92.68 994.285 89.6467 1002.78 83.58C1007.07 80.5 1010.48 76.2067 1013 70.7C1015.52 65.1933 1016.78 58.9867 1016.78 52.08C1016.78 45.08 1015.38 38.8733 1012.58 33.46C1009.87 28.0467 1006.33 23.7067 1001.94 20.44C993.072 13.72 983.925 10.36 974.498 10.36L951.258 11.76ZM949.298 2.52L984.018 1.12C996.992 1.12 1007.21 5.32001 1014.68 13.72C1022.24 22.12 1026.02 33.3667 1026.02 47.46C1026.02 61.46 1020.61 73.8267 1009.78 84.56C999.045 95.2 986.445 100.52 971.978 100.52H927.738V96.32H932.498C935.298 96.32 937.118 95.8067 937.958 94.78C938.798 93.7533 939.218 92.2133 939.218 90.16V12.88C939.218 10.7333 938.752 9.19333 937.818 8.26C936.978 7.23334 935.205 6.72001 932.498 6.72001H927.738V2.52H949.298Z" fill="white"/>
  <path className='path' d="M1078.98 103.32C1066.76 103.32 1056.54 98.6067 1048.32 89.18C1040.2 79.7533 1036.14 68.88 1036.14 56.56C1036.14 48.9067 1037.82 41.5333 1041.18 34.44C1044.54 27.3467 1048.84 21.3267 1054.06 16.38C1059.38 11.4333 1065.22 7.46667 1071.56 4.48C1078 1.49333 1084.21 0 1090.18 0C1102.32 0 1112.49 4.71334 1120.7 14.14C1128.92 23.5667 1133.02 34.44 1133.02 46.76C1133.02 55.9067 1130.55 64.8667 1125.6 73.64C1120.66 82.32 1113.94 89.46 1105.44 95.06C1097.04 100.567 1088.22 103.32 1078.98 103.32ZM1087.66 92.68C1097.84 92.68 1106.38 88.9467 1113.28 81.48C1120.28 74.0133 1123.78 64.7733 1123.78 53.76C1123.78 42.7467 1119.72 32.8533 1111.6 24.08C1103.58 15.3067 1093.82 10.92 1082.34 10.92C1072.54 10.92 1063.91 14.4667 1056.44 21.56C1049.07 28.56 1045.38 36.96 1045.38 46.76C1045.38 59.4533 1049.26 70.28 1057 79.24C1064.84 88.2 1075.06 92.68 1087.66 92.68Z" fill="white"/>
</svg>

)

export default Spacer
