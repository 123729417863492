import React, { useEffect } from 'react';
import HeroBanner from "../components/heroBanner"
import ProjectFeed from "../components/projectFeed"
import Layout from "../components/layout"
import Spacer from "../components/spacer"
import About from "../components/about"
import Seo from "../components/seo"
import Cta from "../components/cta"
import { TweenMax} from "gsap"
import { graphql } from "gatsby"

const IndexPage = ({data}) => {
  useEffect(() => {

    if(window.innerWidth < 768) {
      return;
    }

    let cursor = document.querySelector('.cursor');
    let follower = document.querySelectorAll('.cursor-follower'); 
    let links = document.querySelectorAll("a")
    let mouseX = 0;
    let mouseY = 0;
    let posX = 0;
    let posY = 0;
    
    window.addEventListener('mousemove', (e)=> {
      mouseX = e.clientX;
      mouseY = e.clientY;
    })

    TweenMax.to({}, 0.016, {
      repeat: -1,
      onRepeat: function() {
        posX += (mouseX - posX) / 9;
        posY += (mouseY - posY) / 9;
        
        TweenMax.set(follower, {
            css: {    
            left: posX - 12,
            top: posY - 12
            }
        });
        
        TweenMax.set(cursor, {
            css: {    
            left: mouseX,
            top: mouseY
            }
        });
      }
    });

    links.forEach(link => {
      link.addEventListener('mouseenter', () => {
        TweenMax.to(follower, 0, {
          scale: 2,
          ease: "Power4.easeIn"
        });
      });
      link.addEventListener('mouseleave', () => {
        TweenMax.to(follower, 0, {
          scale: 1,
          ease: "Power4.easeOut"
        });
      });
    });

  })
  return (
  <Layout>   
    <div className='cursor'/>
    <div className='cursor-follower'/>
    <Seo title="Filmmaker, Photographer & Creative Director" />
    <HeroBanner />   
    <ProjectFeed projects={data.projects.edges} />
    <div className='bg-black pos-rel z-3'>
      <Spacer className='m-hide' />
      <About content={data.homepage.introduction} logos={data.homepage.logos} />
      <Spacer />
      <Cta />
      <Spacer />
    </div>
  </Layout>
  )
}

export default IndexPage

export const datoQuery = graphql`
  query {
    homepage:datoCmsHomepage {
      introduction
      logos {
        gatsbyImageData
      }
    }
    projects:allDatoCmsProject(sort: {fields: position}) {
      edges {
        node {
          name
          link
          video
          assetRatio
          clientDate
          image {
            gatsbyImageData
          }
          gallery {
            gatsbyImageData
          }
        }
      }
    }
  }
`