import * as React from "react"

const CTA = () => (
  <div className='p30 justify-center align-center fade--in delay-100 flex m-wrap m-center' data-sal>
    <p className=' m-max-300 large text-initial op-70 mr20 m-100 m-m0 m-mb40'>If you're interested in collaborating with Anthony, please get in touch</p>
    <a href='mailto:info@anthonykdo.com' className='button'>Contact Today</a>
  </div>
)

export default CTA
