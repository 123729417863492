import React, { useEffect } from 'react'
import Logo from "./logo"
import { gsap } from "gsap"

const HeroBanner = () => {
  useEffect(() => {
    document.querySelector('.logo--main').classList.add('active')
    gsap.to(".hero--banner .subtitle, .masthead", { opacity: 1, y:0, duration: .75, delay:1.5 })
    gsap.to(".hero--banner", { ease:'Power3.easeOut', duration:0, delay:2.25, onComplete:function(){ document.querySelector('.first-project').classList.add('sal-animate')}})
  });
  return (
  <div className='h-100vh flex hero--banner'>
    <div className='ma text-center title-area'>
      <Logo />
      <p className='m0 uppercase mt40 m-mt20 medium op-70 subtitle m-small'>Filmmaker, Photographer & Creative Director</p>
    </div>
  </div>
  )
}

export default HeroBanner
