import React, { useEffect } from "react"
import Spacer from "./spacer"
import { GatsbyImage } from "gatsby-plugin-image"
import { Swiper, SwiperSlide } from "swiper/react"
import { EffectFade, Pagination, Autoplay } from "swiper"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import "swiper/css/effect-fade"
import "swiper/css"

const ProjectFeed = ({ projects }) => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    let gsapContext
    const wrapper = document.querySelector("main")
    gsapContext = gsap.context(() => {
      const overlayTimeline = gsap
        .timeline({
          scrollTrigger: {
            trigger: ".project--feed",
            start: "top 70%",
            end: "55% bottom",
            scrub: 0,
          },
        })
        .to(".overlay", { opacity: 1 }).fromTo(".hero--banner",{opacity:1},{opacity:0},0)
        console.log(overlayTimeline)
    }, wrapper)
    return () => gsapContext.revert()
  }, [])
  return (  
    <>
      <div className="project--feed p30 mt-100vh pos-rel">
        <div className="overlay" />
        {projects?.map((project, index) => {
          project = project.node
          return <Project project={project} key={index} index={index} />
        })}
      </div>
    </>
  )
}

export default ProjectFeed

const Project = ({ project, index }) => {
  var ratio = "ratio-1-1"
  var width = "w-50"
  var margin = ""
  if (index % 2) {
    margin = "ml0"
  } else {
    margin = "mr0"
  }
  if (index === 0) {
    margin = ""
  }
  if (project.assetRatio === "Portrait") {
    ratio = "ratio-2-1"
    width = "w-40"
  } else if (project.assetRatio === "Landscape") {
    ratio = "ratio-1-2"
    width = "w-60"
  } else if (project.assetRatio === "Wide (21:9)") {
    ratio = "ratio-1-3"
    width = "w-60"
  }

  if (project.link)
    return (
      <>
        <div className="single-project mb40 max-1600 ma">
          <div className={margin + " ma " + width}>
            <div className={"fade--in " + (index === 0 ? "first-project" : "")} data-sal>
              <a href={project.link} target="_blank" aria-label="link" rel="noreferrer">
                <div className={"pos-rel " + ratio}>
                  {project.gallery ? (
                    <Carousel images={project.gallery} featured={project.image} />
                  ) : (
                    <>{project.image ? <GatsbyImage image={project.image?.gatsbyImageData} alt="Gallery Image" className="bg-image" /> : ""}</>
                  )}

                  {project.video ? (
                    <video loop={true} playsInline={true} muted={true} autoPlay={true} className="bg-image">
                      <source src={project.video} />
                    </video>
                  ) : (
                    ""
                  )}
                </div>
              </a>
            </div>
            <div className={"info mt20 fade pos-rel " + (project.link ? "with-link" : "")} data-sal>
              {project.link ? (
                <a href={project.link} className="link bg-image z-1" target="_blank" aria-label="link" rel="noreferrer">
                  <span />
                </a>
              ) : (
                ""
              )}
              <p className="m0 title medium op-70">{project.name}</p>
              <p className="m0 op-70 mt5">
                <span className="op-50">For</span> {project.clientDate}
              </p>
            </div>
          </div>
        </div>
        <Spacer />
      </>
    )

  return (
    <>
      <div className="single-project mb40 max-1600 ma">
        <div className={margin + " ma " + width}>
          <div className={"fade--in " + (index === 0 ? "first-project" : "")} data-sal>
            <div className={"pos-rel " + ratio}>
              {project.gallery ? (
                <Carousel images={project.gallery} featured={project.image} />
              ) : (
                <>{project.image ? <GatsbyImage image={project.image?.gatsbyImageData} alt="Gallery Image" className="bg-image" /> : ""}</>
              )}

              {project.video ? (
                <video loop={true} playsInline={true} muted={true} autoPlay={true} className="bg-image">
                  <source src={project.video} />
                </video>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className={"info mt20 fade pos-rel " + (project.link ? "with-link" : "")} data-sal>
            {project.link ? (
              <a href={project.link} className="link bg-image z-1" target="_blank" aria-label="link" rel="noreferrer">
                <span />
              </a>
            ) : (
              ""
            )}
            <p className="m0 title medium op-70">{project.name}</p>
            <p className="m0 op-70 mt5">
              <span className="op-50">For</span> {project.clientDate}
            </p>
          </div>
        </div>
      </div>
      <Spacer />
    </>
  )
}

const Carousel = ({ images, featured }) => {
  return (
    <Swiper className="bg-image" slidesPerView={1} loop={true} modules={[EffectFade, Pagination, Autoplay]} autoplay={true} effect="fade" pagination={{ el: ".testimonial-pagination", clickable: "true" }}>
      {featured ? (
        <SwiperSlide>
          <GatsbyImage image={featured?.gatsbyImageData} alt="Gallery Image" className="bg-image" />
        </SwiperSlide>
      ) : (
        ""
      )}
      {images?.map((image, index) => {
        return (
          <SwiperSlide key={index}>
            <GatsbyImage image={image?.gatsbyImageData} alt="Gallery Image" className="bg-image" />
          </SwiperSlide>
        )
      })}
    </Swiper>
  )
}
